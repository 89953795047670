import React from "react";
import styled from "styled-components";
import { format } from "date-fns";
import { Link } from "gatsby";
import { buildImageObj, getBlogUrl, imageUrlFor } from "../../helpers";
import { PortableText } from "../elements";
import { colour, media, font } from "../styled";

const BlogPostPreview = ({
  isInList,
  publishedAt,
  slug,
  mainImage,
  title,
  rawExcerpt,
  isCuratedLinkItem,
  tags = [],
}) => (
  <StyledLink to={getBlogUrl(slug.current)}>
    <Content isInList={isInList} isCuratedLinkItem={isCuratedLinkItem}>
      <LeadMediaThumb>
        {mainImage && mainImage.asset && (
          <img
            src={imageUrlFor(buildImageObj(mainImage))
              .width(309)
              .height(133)
              .auto("format")
              .url()}
            width={309}
            height={133}
            aspect-ratio={309 / 133}
            loading="lazy"
            alt={mainImage.alt}
          />
        )}
      </LeadMediaThumb>
      {isCuratedLinkItem ? (
        <CuratedLinkTitle>{title}</CuratedLinkTitle>
      ) : (
        <TextContent>
          <Title isInList={isInList}>{title}</Title>
          {tags && tags.length > 0 ? (
            <>
              <Tags>
                {tags.map(({ tag }) => (
                  <Tag key={tag.title}>{tag.title}</Tag>
                ))}
              </Tags>
            </>
          ) : (
            <Tags>
              <Tag>Book Review</Tag>
            </Tags>
          )}
          {rawExcerpt && (
            <Para>
              <PortableText blocks={rawExcerpt} />
            </Para>
          )}
          <StyledDate>
            {format(new Date(publishedAt), "MMMM do, yyyy")}
          </StyledDate>
        </TextContent>
      )}
    </Content>
  </StyledLink>
);

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.primaryColor};
  text-decoration: none;
`;

const Content = styled.div`
  /* width: 267px; */
  box-shadow: -2px -1px 15px 10px #ebebeb, 5px 5px 5px 5px #ebebeb;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  ${(props) =>
    props.isInList &&
    `${media.minMedium} {
      display: grid;
      grid-template-columns: 1fr 2fr;
      grid-gap: 2em;     
    }`}
  ${(props) =>
    props.isCuratedLinkItem
      ? `
    height: 100px;
    text-align: center;
    `
      : `height: 479px`}
`;

const LeadMediaThumb = styled.div`
  position: relative;

  img {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    width: 100%;
    height: 133px;
  }
`;

const CuratedLinkTitle = styled.h3`
  text-align: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
`;

const TextContent = styled.div`
  padding: 17px;
  height: 308px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Title = styled.h3`
  text-align: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
`;

const Para = styled.div`
  p {
    margin: 0;
  }
`;

const Tags = styled.div`
  text-align: center;
  text-transform: uppercase;
  font-size: ${font.smallSize};
  line-height: ${font.smallLineHeight};
  color: ${colour.darkGray};
`;

const Tag = styled.span`
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  &:not(:last-child) {
    border-right: 1px solid ${colour.lightGray};
  }
`;

const StyledDate = styled.div`
  position: relative;
  bottom: 0;
  font-size: ${font.smallSize};
  line-height: ${font.smallLineHeight};
  color: ${colour.darkGray};
`;

export default BlogPostPreview;
